<template>
  <div class="layoutMind">
    <div class="layoutTop">
      <div class="layoutTopLeft">
        <img
          src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111162431_logoColor.png"
          class="layoutTopImg"
        />
      </div>
      <div>安徽英制马力信息技术</div>
    </div>
    <div class="layoutMiddle">
      <div class="layoutBody">
        <div class="layoutBodyLeft"></div>
        <div class="layoutBodyRight" v-if="showLogin">
          <div class="layoutBodyTop">
            <input type="text" class="layoutBodyTopInput" placeholder="账号" />
            <div class="layoutBodyTopIcon">
              <img
                src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111164937_zhang.png"
                class="layoutInputIcon"
              />
            </div>
          </div>
          <div class="layoutBodyCode">
            <input type="text" class="layoutBodyCodeInput" placeholder="验证码" />
            <div class="layoutBodyTopIcon">
              <img
                src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111171749_code.png"
                class="layoutInputIcon"
              />
            </div>
            <div class="layoutBodyCodeBtn" @click="codeClick">{{ code }}</div>
          </div>
          <div class="layoutBodyItem">
            <input type="text" class="layoutBodyTopInput" placeholder="密码" />
            <div class="layoutBodyTopIcon">
              <img
                src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111171530_suo.png"
                class="layoutInputIcon"
              />
            </div>
          </div>
          <div class="layoutBodyItem">
            <input
              type="text"
              class="layoutBodyTopInput"
              placeholder="确认密码"
            />
            <div class="layoutBodyTopIcon">
              <img
                src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111171530_suo.png"
                class="layoutInputIcon"
              />
            </div>
          </div>
          <div class="layoutBodyRow" @click="loginShow">登录</div>
          <div class="layoutBodyBtn" @click="registerClick">免费注册</div>
          <div class="layoutBodyRadio">
            <el-radio label="1" size="large"
              >您已阅读并同意《软件服务协议》和 《隐私权协议》</el-radio
            >
          </div>
        </div>
        <div class="layoutBodyRight" v-else>
          <div class="layoutBodyRegTop">
            <input
              type="text"
              class="layoutBodyTopInput"
              v-model="infor.username"
              placeholder="账号"
            />
            <div class="layoutBodyTopIcon">
              <img
                src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111164937_zhang.png"
                class="layoutInputIcon"
              />
            </div>
          </div>
          <div class="layoutBodyItem">
            <input
              type="text"
              class="layoutBodyTopInput"
              v-model="infor.password"
              placeholder="密码"
            />
            <div class="layoutBodyTopIcon">
              <img
                src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111171530_suo.png"
                class="layoutInputIcon"
              />
            </div>
          </div>
          <div class="layoutBodyRow" @click="registerShow">注册</div>
          <div class="layoutBodyBtn" @click="loginClick">登录</div>
          <div class="layoutBodyRadio">
            <el-radio label="1" size="large"
              >您已阅读并同意《软件服务协议》和 《隐私权协议》</el-radio
            >
          </div>
        </div>
      </div>
    </div>
    <div class="layoutFoot"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLogin: true,
      code: "发送验证码",
      count: "",
      timeSet: null,  //初始定时器
      showCode: true,
      infor:{
        username:'',
        password: ''
      }
    };
  },
  created() {},
  methods: {
    // 验证码
    codeClick() {
      if(this.showCode){
        if (this.code == "发送验证码") {
        this.count = 60;
        this.code = this.count + "s";
        this.timeSet = setInterval(() => {
          this.countDown()
        }, 1000);
      }
      }else{
        this.$message({
          message: '请勿重复操作',
          type: "info", //info , error ,success
          center: true,
        });
      }
      
    },
    // 倒计时
    countDown() {
      if (this.count > 0) {
        this.showCode = false
        this.count = this.count - 1;
          this.code = this.count + "s";
      }else if(this.count == 0){
        console.log('111' ,this.count);
        this.showCode = true
        this.code = "重新发送"
        clearInterval(this.timeSet)
        this.timeSet = null
      }
    },
    // 切换登录
    loginShow() {
      this.showLogin = false;
    },
    // 切换注册
    registerShow() {
      this.showLogin = true;
    },
    // 注册点击
    registerClick() {},
    // 后台登录
    loginClick() {
      if(this.$utils.isEmpty(this.infor.username)){
        this.$message({
          message: '账号有误',
          type: "info", //info , error ,success
          center: true,
        });
      }else if(this.$utils.isEmpty(this.infor.password)){
        this.$message({
          message: '密码有误',
          type: "info", //info , error ,success
          center: true,
        });
      }else{
        this.$api.api1('auth/login?login_type=1',this.infor,(res) => {
          console.log('登录回调', res);
          sessionStorage.setItem("token", res.data.data.access_token);
          this.$message({
            message: '登录成功',
            type: "success", //info , error ,success
            center: true,
          });
          this.$router.push({ path: "/blayout" });
        })
      }
      
      // this.$router.push({ path: "/blayout" });
    },
  },
};
</script>

<style>
.layoutMind {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f7f9;
}

.layoutTop {
  width: 100%;
  height: 80px;
  padding: 0px 70px;
  box-sizing: border-box;
  color: #246496;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.layoutTopLeft {
  width: 63px;
  height: 40px;
  margin-right: 30px;
}

.layoutTopImg {
  width: 100%;
  height: 100%;
}

.layoutMiddle {
  width: 100%;
  height: 660px;
  background-image: url("https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111162731_bgc.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layoutBody {
  width: 855px;
  height: 500px;
  display: flex;
}

.layoutBodyLeft {
  width: 420px;
  height: 100%;
  background-image: url("https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111163501_cardLeft.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.layoutBodyRight {
  width: 435px;
  height: 100%;
  background-color: white;
  padding: 35px 50px;
  box-sizing: border-box;
}

.layoutBodyTop {
  position: relative;
  width: 320px;
  height: 40px;
  margin-top: 20px;
}

.layoutBodyRegTop {
  position: relative;
  width: 320px;
  height: 40px;
  margin-top: 80px;
}

.layoutBodyTopIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 43px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
}

.layoutInputIcon {
  width: 19px;
  height: 20px;
}

.layoutBodyItem {
  position: relative;
  width: 320px;
  height: 40px;
  margin-top: 28px;
}

.layoutBodyTopInput {
  width: 320px;
  height: 40px;
  padding-left: 43px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
}

.layoutBodyCode {
  position: relative;
  width: 320px;
  height: 40px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
}

.layoutBodyCodeInput {
  width: 180px;
  height: 40px;
  padding-left: 43px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
}

.layoutBodyCodeBtn {
  width: 120px;
  height: 40px;
  border-radius: 10px;
  background-color: #3d7eff;
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
}

.layoutBodyCodeBtn:hover {
  cursor: pointer;
}

.layoutBodyRow {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: end;
  color: #707070;
  font-size: 16px;
}

.layoutBodyRow:hover {
  cursor: pointer;
  color: #3d7eff;
}

.layoutBodyBtn {
  width: 320px;
  height: 40px;
  border-radius: 10px;
  background-color: #3d7eff;
  color: white;
  text-align: center;
  line-height: 40px;
}

.layoutBodyBtn:hover {
  cursor: pointer;
}

.layoutBodyRadio {
  width: 320px;
  height: 60px;
  color: #858585;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.layoutFoot {
  width: 100%;
  height: calc(100% - 80px - 660px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>